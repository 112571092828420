import React from 'react';
import Moment from 'moment'
import { Markup } from "react-render-markup"

class StatusComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      systemOperational: true,
      extraInfo: '',
      loaded: false,
      systemStatusSelect: '',
      extraSystemInfo: ''
    }
  }


  componentDidMount() {
    //Fetching incidents
    fetch("https://sitebunker-app.herokuapp.com/incidents")
      .then(res => res.json())
      .then(
        (result) => {

          let incidents = result.sort(function(a, b) {
            return a.investigateDate - b.investigateDate
          });

          incidents.reverse();

          //Show only first incident, remove for showing all incidents;
          let first_incident = [];
          first_incident.push(incidents[0])

          //Replace with incidents array
          this.setState({
            items: first_incident
          })
        },
      )

    fetch("https://sitebunker-app.herokuapp.com/system-status")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            extraInfo: result.extraInfo,
            systemOperational: result.systemOperational,
            systemStatusSelect: result["SystemStatusSelect"],
            extraSystemInfo: result["extraSystemInfoEN"],
            loaded: true
          })
        })
  }

  render() {
    return (

      <div className="status-content">
        {this.state.loaded ?
          <div>
            <div className="status-container">
              {/*{this.state.systemOperational ?*/}
              {/*  <div className='d-flex align-items-center mb-5'>*/}
              {/*    <i className='bx bx-check mr-3 operational-icon font-weight-bold d-md-inline-block d-none'/>*/}
              {/*    <p className="sys-info system-operational">All systems are operational</p>*/}
              {/*  </div> :*/}
              {/*  <div className='d-flex align-items-center mb-5'>*/}
              {/*    <i className='bx bx-error not-operational-icon mr-3 font-weight-bold d-md-inline-block d-none'/>*/}
              {/*    <p className="sys-info system-not-operational">Reported incident</p>*/}
              {/*  </div>*/}
              {/*}*/}

              {this.state.systemStatusSelect === "on" ?
                <div className='d-flex align-items-center mb-5'>
                  <i className='bx bx-check mr-3 operational-icon font-weight-bold d-md-inline-block d-none'/>
                  <p className="sys-info system-operational">All systems are operational</p>
                </div> : this.state.systemStatusSelect === "off" ?
                  <div className='d-flex align-items-center mb-5'>
                    <i className='bx bx-error not-operational-icon mr-3 font-weight-bold d-md-inline-block d-none'/>
                    <p className="sys-info system-not-operational">Reported incident</p>
                  </div> : this.state.systemStatusSelect === "maintenance" ?
                    <div className='d-flex align-items-center mb-5'>
                      <i className='bx bx-loader mr-3 maintenance-icon font-weight-bold d-md-inline-block d-none'/>
                      <p className="sys-info system-maintenance">Scheduled maintenance</p>
                    </div> : null
              }

              <Markup markup={this.state.extraSystemInfo} />

            </div>

            {
              this.state.items[0] !== undefined ?
                <div className="incidents-list">
                  <hr/>

                  {this.state.items.map(function(incident, index) {
                    return (
                      <div key={index} className="single-incident">
                        <h3>{incident.title} {Moment(incident.investigatecDate).format('DD-MM-yyyy HH:mm')}</h3>
                        <p><b>Details</b></p>
                        <p className='mb-3'>{incident.investigateDetails}</p>


                        {incident.update !== null ? <div className="incident-update">
                          <p><b>Update:</b> {Moment(incident.updateDate).format('DD-MM-yyyy HH:mm')}</p>
                          <p className='mb-3'>{incident.updateDetails}</p>
                        </div> : <div />
                        }

                        {incident.solvedDate !== null ? <div className="incident-solved">
                          <p><b>Solved at:</b> {Moment(incident.solvedDate).format('DD-MM-yyyy HH:mm')}</p>
                        </div> : <div />
                        }
                      </div>
                    )
                  })}
                </div>
                : ''
            }

          </div>
          :
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        }



      </div>
    )
  }
}

export default StatusComponent;
