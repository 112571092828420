import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import StatusLayout from "../components/StatusComponent/StatusLayout"
import SEO from "../components/App/SEO"

const Status = () => {

  return (
    <Layout>
      <SEO
      title="Service status - Sitebunker.net"
      />
      <Navbar />
      <StatusLayout />
      <Footer />
    </Layout>
  )
}

export default Status
